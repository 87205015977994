var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slider-componente"},[_c('ViuBox',[_c('div',{staticClass:"w-100 cabecera-slider"},[_c('div',{staticClass:"flex"},[_c('div',{class:{
            'title-periodo': _vm.type === 'individual',
            'title-periodo-grouped': _vm.type === 'grouped'
          }},[_vm._v(" "+_vm._s(_vm.periodo)+" ")]),(_vm.type === 'grouped' && _vm.period === 0)?_c('div',{class:{
            'title-periodo': _vm.type === 'individual',
            'title-periodo-grouped': _vm.type === 'grouped'
          }},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e()]),(_vm.typeFee === 'potencia')?_c('div',{staticClass:"body-informacion"},[(_vm.precioBoe === 'S')?_c('div',{staticClass:"informacion mr-10"},[_c('span',[_vm._v("Precio BOE")]),_c('span',[_vm._v(_vm._s(_vm.moneyFormat(_vm.potenciaPrecio))+" €/kW año")])]):_vm._e(),_c('div',{staticClass:"informacion"},[_c('span',[_vm._v("Pot. actual")]),_c('span',[_vm._v(_vm._s(_vm.potenciaActualCalc)+" kW")])])]):_vm._e()]),_c('div',{staticClass:"w-100 flex align-center last-block mt-5",class:{
        'flex-end': _vm.title === 'Fee periodos agrupados' || _vm.title === 'Margen periodos agrupados'
      }},[_c('ViuLabel',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'individual' || (_vm.type === 'grouped' && _vm.period === 0)),expression:"type === 'individual' || (type === 'grouped' && period === 0)"}],staticClass:"w-100",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"lb black"},[_vm._v("Configurador Fee")]),_c('div',{staticClass:"leyend black"},[_vm._v(_vm._s(_vm.postInputSlider))])]},proxy:true}])},[_c('VueSlider',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'individual' || (_vm.type === 'grouped' && _vm.period === 0)),expression:"type === 'individual' || (type === 'grouped' && period === 0)"}],staticClass:"slider w-100",attrs:{"min":_vm.min,"max":_vm.max,"width":'auto',"tooltip":'none',"height":6,"interval":0.5,"wait-to-emit":true,"rail-style":{ background: '#D7E1EA' },"process-style":{ background: '#35425B' },"marks":[_vm.min, _vm.max]},model:{value:(_vm.slider),callback:function ($$v) {_vm.slider=$$v},expression:"slider"}})],1),_c('ViuLabel',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'individual' || (_vm.type === 'grouped' && _vm.period === 0)),expression:"type === 'individual' || (type === 'grouped' && period === 0)"}],class:{
          'w-50': _vm.typeFee === 'potencia',
          'w-100': _vm.typeFee !== 'potencia'
        },scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"lb"},[_vm._v("Fee"),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"leyend"},[_vm._v(_vm._s(_vm.postInputLabel))])]},proxy:true}])},[_c('ViuInput',{ref:"fee",staticClass:"w-25",attrs:{"type":'number',"debounce":800},model:{value:(_vm.inputModel),callback:function ($$v) {_vm.inputModel=$$v},expression:"inputModel"}})],1),_c('ViuLabel',{class:{
          'w-50': _vm.typeFee === 'potencia',
          'w-100':
            (_vm.typeFee !== 'potencia' && _vm.title !== 'Fee periodos agrupados') ||
            (_vm.title === 'Fee periodos agrupados' && _vm.period === 0),
          'w-31': _vm.title === 'Fee periodos agrupados' && _vm.period !== 0,
          'w-146': _vm.title === 'Margen periodos agrupados' && _vm.period !== 0
        },scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"lb"},[_vm._v("Precio Final")]),_c('div',{staticClass:"leyend"},[_vm._v(_vm._s(_vm.postInputLabelPrecioFinal))])]},proxy:true}])},[_c('ViuInput',{staticClass:"w-25",attrs:{"type":'number',"read-only":true},model:{value:(_vm.importeFinal),callback:function ($$v) {_vm.importeFinal=$$v},expression:"importeFinal"}})],1),(_vm.typeFee === 'potencia')?_c('ViuLabel',{staticClass:"w-50",class:{
          'w-146': _vm.title === 'Margen periodos agrupados' && _vm.period !== 0
        },scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"lb"},[_vm._v("Editar potencia"),[_vm._v("*")]],2)]},proxy:true}],null,false,2257543821)},[_c('ViuInput',{ref:"potencia",staticClass:"w-25 potencia",attrs:{"type":'number',"required":_vm.requiredPotencia},scopedSlots:_vm._u([{key:"post-input",fn:function(){return [_vm._v("kW")]},proxy:true}],null,false,3740008258),model:{value:(_vm.potencia),callback:function ($$v) {_vm.potencia=$$v},expression:"potencia"}})],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }